import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import Button from "../PageComponents/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderWrapper = styled.section`
  position: relative;
`;

const Slide = styled.article`
  padding: 60px 47px;

  @media (min-width: 768px) {
    padding: 90px 118px;
  }

  @media (min-width: 1024px) {
    display: flex;
    padding: 120px 80px;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const GreyBackground = styled.div`
  position: absolute;
  background-color: #f3f4f5;
  z-index: -1;

  &.copy {
    height: 194px;
    top: 0;
    left: 0;
    right: 0;
  }

  &.stats,
  &.testimonial {
    left: 0;
    right: 0;
    bottom: 0;
    height: 403px;
  }

  @media (min-width: 768px) {
    &.copy {
      height: 335px;
    }
    &.stats,
    &.testimonial {
      height: 697px;
      height: 75%;
    }
  }

  @media (min-width: 1024px) {
    height: auto;
    &.copy {
      top: 0;
      left: 0;
      bottom: 0;
      width: 37.113%;
      height: auto;
    }
    &.stats,
    &.testimonial {
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      width: 75%;
      max-width: 1600px;
      height: auto;
    }
  }
`;

const SlideContent = styled.div`
  opacity: 1;
  transition: opacity 0.25s ease;

  &.transitioning {
    opacity: 0;
  }
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    height: 500px;
    width: 100%;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  font-family: attribute-mono, monospace;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(90deg);
    transform: rotate(-90deg);
    width: 500px;
    transform-origin: 0 0;
    top: calc(100% - 120px);
  }
`;

const NavButton = styled.button`
  padding: 0;
  text-transform: uppercase;
  border: 0;
  background-color: transparent;
  font-family: attribute-mono, monospace;
  color: var(--color-grey);
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
`;

const Numbers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-grey);
`;

const Num = styled.span`
  &:first-of-type {
    margin-right: 10px;
  }
  &:last-of-type {
    margin-left: 10px;
  }
`;

const Line = styled.div`
  height: 1px;
  background-color: var(--color-grey);
  background-position: center;
  width: 50px;

  @media (min-width: 768px) {
    width: 125px;
  }

  @media (min-width: 1024px) {
    width: 105px;
  }
`;

const SlideImage = styled.div`
  background-position: center;
  background-size: cover;
  background-image: ${props => `url(${props.bg})`};
  margin-bottom: 40px;
  width: 100%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  img {
    display: none;
  }

  @media (min-width: 768px) {
    margin-bottom: 60px;
  }

  @media (min-width: 1024px) {
    height: auto;
    min-width: 50%;
    width: 50%;
    margin: 0 60px;
  }

  @media (min-width: 1200px) {
    height: 500px;
    min-width: 500px;
    width: 500px;
  }
`;
const Image = styled.img`
  max-width: 100%;
  width: 100%;
  opacity: 0;
`;

const SlideDescription = styled.aside`
  text-align: center;
  color: var(--color-offBlack);

  @media (min-width: 1024px) {
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media (min-width: 1300px) {
    padding-top: 60px;
    display: block;
  }
`;

const SectionTitle = styled.h2`
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.1px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.15px;
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.16px;
    margin-bottom: 12px;
  }
`;

const SlideHeading = styled.h3`
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  letter-spacing: -0.81px;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -1.16px;
    margin-bottom: 10px;
  }

  @media (min-width: 1300px) {
    font-size: 64px;
    line-height: 77px;
    letter-spacing: -1.62px;
  }
`;

const SlideCopy = styled.div`
  b,
  strong {
    font-weight: 600;
  }

  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.27px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.36px;
    margin-bottom: 35px;
  }

  @media (min-width: 1300px) {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.41px;
    margin-bottom: 45px;
    max-width: 55ch;
  }
`;

const StatTitle = styled.h2`
  font-weight: 600;
  color: var(--color-magenta);

  font-size: 150px;
  line-height: 181px;
  letter-spacing: -11.17px;

  @media (min-width: 768px) {
    margin-top: -40px;
    font-size: 311px;
    line-height: 375px;
  }

  @media (min-width: 1024px) {
    line-height: 250px;
    text-align: center;
    margin-bottom: 40px;
  }
`;

const StatCopy = styled.p`
  font-family: attribute-mono, monospace;
  text-transform: uppercase;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.15px;
    max-width: 45ch;
    margin: 0 auto;
  }

  @media (min-width: 1024px) {
    text-align: center;
  }
`;

const TestimonialQuote = styled.p`
  text-align: center;
  padding: 20px 0 10px;
  font-weight: 600;
  color: var(--color-magenta);

  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.67px;
  max-width: 23ch;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 20px 0 20px;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.71px;
    max-width: 31.5ch;
  }

  @media (min-width: 1024px) {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: -1.47px;
    padding: 20px 0 50px;
  }
`;

const TestimonialAttribution = styled.p`
  text-transform: uppercase;
  font-family: attribute-mono, monospace;
  color: var(--color-offBlack);

  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
`;

const CarouselComponent = ({ slides, carouselType, autoplay }) => {
  const [currentSlide, setCurrentSlide] = useState(slides[0]);

  const setFade = bool => {
    // adding & removing opacity during the transition
    const slideContent = document.querySelector(".slide-content");
    if (bool) {
      slideContent.classList.add("transitioning");
    } else {
      setTimeout(() => {
        slideContent.classList.remove("transitioning");
      }, 500);
    }
  };

  const GoBack = () => {
    setFade(true);
    let prevIndex = slides.indexOf(currentSlide) - 1;
    if (prevIndex < 0) {
      prevIndex = slides.length - 1;
    }
    const prevSlide = slides[prevIndex];
    setTimeout(() => {
      setCurrentSlide(prevSlide);
    }, 500);
    setFade(false);
  };

  const GoForward = () => {
    setFade(true);
    let nextIndex = slides.indexOf(currentSlide) + 1;
    if (nextIndex >= slides.length) {
      nextIndex = 0;
    }
    const nextSlide = slides[nextIndex];
    setTimeout(() => {
      setCurrentSlide(nextSlide);
    }, 500);
    setFade(false);
  };

  // Autoplay
  useEffect(() => {
    if(autoplay) {
      setTimeout(() => {
        GoForward();
      }, 10000);
    }
  }, [currentSlide]);

  const renderContent = carouselType => {
    switch (carouselType) {
      case "copy":
        const parseHttp =
          currentSlide.link.url.includes("http//") ||
          currentSlide.link.url.includes("https//");
        let destination = parseHttp
          ? currentSlide.link.url.split("//")[1]
          : currentSlide.link.url;

        // links should be entered as just the slug in Wordpress
        // if there's an 'https://' or 'http://` present, let's parse it as the destination

        return (
          <SlideDescription>
            {slides?.title && <SectionTitle>{slides?.title}</SectionTitle>}
            <SlideHeading>{currentSlide.title}</SlideHeading>
            <SlideCopy
              dangerouslySetInnerHTML={{ __html: currentSlide.copy }}
            />
            {currentSlide?.link && (
              <Link to={`${destination}/`}>
                <Button style={"dark"}>
                  {currentSlide?.link?.title
                    ? currentSlide?.link?.title
                    : "Learn More"}
                </Button>
              </Link>
            )}
          </SlideDescription>
        );
        break;
      case "stats":
        return (
          <SlideDescription>
            <StatTitle>{currentSlide?.stat}</StatTitle>
            <StatCopy>{currentSlide?.copy}</StatCopy>
          </SlideDescription>
        );
        break;
      case "testimonial":
        return (
          <SlideDescription>
            <TestimonialQuote>{currentSlide?.testimonial}</TestimonialQuote>
            <TestimonialAttribution>
              — {currentSlide?.attribution}
            </TestimonialAttribution>
          </SlideDescription>
        );
        break;

      default:
        break;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: autoplay || false,
  };

  return (
    <SliderWrapper>
      <GreyBackground className={carouselType} />
      <Slide>
        <Nav>
          <NavButton onClick={GoBack}>Prev</NavButton>
          <Numbers>
            <Num>{slides?.indexOf(currentSlide) + 1}</Num>
            <Line />
            <Num>{slides?.length}</Num>
          </Numbers>
          <NavButton onClick={GoForward}>Next</NavButton>
        </Nav>
        <SlideContent className="slide-content">
          <SlideImage
            bg={currentSlide?.image?.source_url || currentSlide?.image?.url}
          >
            <Image src={currentSlide?.image?.source_url} alt={slides?.title} />
          </SlideImage>
          {renderContent(carouselType)}
        </SlideContent>
      </Slide>
    </SliderWrapper>
  );
};

export default CarouselComponent;
