import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { styled } from "twin.macro";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/seo";
import PageHero from "../../components/PageSections/PageHero";
// import FullVideo from "../../components/PageSections/FullVideo";
import FeaturedStories from "../../components/PageSections/FeaturedStories";
import StoriesGrid from "../../components/PageSections/StoriesGrid";
import ContactUs from "../../components/PageSections/ContactUs";
import GetInvolved from "../../components/PageSections/GetInvolved";
import Carousel from "../../components/PageSections/Carousel";

const SectionTitle = styled.h3`
  font-weight: 600;
  text-align: center;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.83px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 55px;
    letter-spacing: -2.51px;
    width: 100%;
  }

  @media (min-width: 1024px) {
    font-size: 90px;
    line-height: 91px;
    line-height: -4.11px;
  }
`;

const StoriesPage = () => {
  const data = useStaticQuery(graphql`
    {
      storiesData: allWordpressPage(filter: { slug: { eq: "successes" } }) {
        nodes {
          title
          acf {
            full_video {
              title
              video
              poster {
                source_url
              }
            }
            testimonials {
              image {
                source_url
              }
              testimonial
              attribution
            }
            featured_stories {
              title
              description
              age
              image {
                source_url
              }
              story {
                post_name
              }
            }
            hero {
              title
              cta_type
              cta_text
              cta_link
              copy
              artist {
                credit
                age
              }
              art {
                source_url
              }
            }
            contact
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
      homeData: allWordpressPage(filter: { slug: { eq: "home" } }) {
        nodes {
          acf {
            full_video {
              title
              video
              poster {
                source_url
              }
            }
          }
        }
      }
    }
  `);
  // const videoData = data?.homeData?.nodes[0].acf?.full_video;
  const storiesData = data?.storiesData.nodes[0].acf;
  const { yoast_meta, title } = data?.storiesData.nodes[0];
  const { hero, contact } = storiesData;

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <PageHero {...hero} />
      <FeaturedStories featured_stories={storiesData.featured_stories} />
      <SectionTitle>Testimonials</SectionTitle>
      <Carousel
        carouselType={"testimonial"}
        slides={storiesData.testimonials}
        autoplay={true}
      />
      <div className="section-border">
        <StoriesGrid />
      </div>
      <ContactUs content={contact} />
      <GetInvolved />
    </Layout>
  );
};

export default StoriesPage;
