import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import Slider from "react-slick";
import DisclaimerText from "../PageComponents/DisclaimerText";
import Button from "../PageComponents/Button";
import ArrowLeft from "../../images/ICON_GREY_ARROWLEFT.svg";
import ArrowRight from "../../images/ICON_GREY_ARROWRIGHT.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeaturedStoriesWrapper = styled.section`
  position: relative;
  padding: 59px 48px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 89px 118px;
  }

  @media (min-width: 1280px) {
    padding: 120px 188px;
  }
`;

const SliderWrapper = styled.div`
  margin-top: -7.5px;
  .slick-slide {
    pointer-events: none;

    &.slick-active,
    &.slick-current {
      pointer-events: all;
    }
  }
  .slick-prev,
  .slick-next {
    top: 70%;
  }
  .slick-prev {
    &::before {
      background-size: 12px;
      background-repeat: no-repeat;
      background-image: url(${ArrowLeft});
      background-position: center;
      color: transparent;
      height: 11px;
      width: 11px;
    }
  }
  .slick-next {
    &::before {
      background-size: 12px;
      background-image: url(${ArrowRight});
      background-position: center;
      background-repeat: no-repeat;
      color: transparent;
      height: 11px;
      width: 11px;
    }
  }
  @media (min-width: 768px) {
    .slick-prev,
    .slick-next {
      top: 75%;
    }
    .slick-prev {
      &::before {
        background-size: 20px;

        height: 32px;
        width: 32px;
      }
    }
    .slick-next {
      &::before {
        background-size: 20px;

        height: 32px;
        width: 32px;
      }
    }
  }
  @media (min-width: 1280px) {
    margin-top: -5px;
    max-width: 1440px;
    margin: 0 auto;

    .slick-prev,
    .slick-next {
      top: 50%;
    }
    .slick-prev {
      left: -75px;
    }
    .slick-next {
      right: -75px;
    }
  }
`;

const Title = styled.h3`
  font-weight: 600;

  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.56px;
    margin-bottom: 20px;
  }

  @media (min-width: 1280px) {
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.68px;
    margin-bottom: 30px;
  }
`;

const Subtitle = styled.h4`
  font-weight: 600;
  font-size: 50px;
  line-height: 45px;
  letter-spacing: -2.18px;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    font-size: 100px;
    line-height: 85px;
    letter-spacing: -3.63px;
  }

  @media (min-width: 1280px) {
    font-size: 150px;
    line-height: 116px;
    letter-spacing: -6.45px;
    margin-bottom: 5rem;
  }
`;

const Slide = styled.article`
  background-color: #ffffff;
  text-align: center;
  width: 98% !important;
  padding-bottom: 5px;
  &:focus {
    outline: 1px solid transparent;
  }

  @media (min-width: 1280px) {
    text-align: left;
  }
`;

const SlideContent = styled.div`
  padding-bottom: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);

  @media (min-width: 1280px) {
    display: flex;
    max-width: 1440px;
    padding-bottom: 0;
    margin: 0 auto;
  }
`;

const Content = styled.aside`
  @media (min-width: 1280px) {
    flex: 1;
    padding: 90px 50px;
  }
`;

const SlideImage = styled.div`
  background-image: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
  background-color: grey;
  width: 100%;
  height: 24rem;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  img {
    display: none;
  }

  margin-bottom: 30px;

  @media (min-width: 1280px) {
    margin-bottom: 0;
    img {
      opacity: 0;
      display: none;
    }
    width: 50%;
    height: auto;
  }
`;

const SlideTitle = styled.p`
  font-weight: 600;

  font-size: 32px;
  line-height: 35px;
  letter-spacing: -0.81px;
  margin-bottom: 2px;

  @media (min-width: 768px) {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -1.16px;
  }

  @media (min-width: 1280px) {
    font-size: 64px;
    line-height: 77px;
    letter-spacing: -1.65px;
  }
`;

const Age = styled.p`
  font-weight: 600;

  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.56px;
  }

  @media (min-width: 1280px) {
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.68px;
  }
`;

const Description = styled.p`
  ${tw`max-w-xl mx-auto px-4 xl:px-0 xl:max-w-full`}
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.27px;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.27px;
  }

  @media (min-width: 1280px) {
    margin: 10px 0 45px;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.4px;
  }
`;

const FeaturedStories = ({ featured_stories }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1
  };
  return (
    <FeaturedStoriesWrapper>
      <Subtitle>See Our Successes</Subtitle>
      <SliderWrapper>
        <Slider {...sliderSettings}>
          {featured_stories.map((story, index) => (
            <Slide key={index}>
              <SlideContent>
                <SlideImage bg={story.image.source_url}>
                  <img src={story.image.source_url} alt={story.title} />
                </SlideImage>
                <Content>
                  <SlideTitle>
                    <Link
                      className="text-primary"
                      to={`/successes/${story.story.post_name}/`}
                    >
                      {story.title}
                    </Link>
                  </SlideTitle>
                  <Age>{story.age}</Age>
                  <Description>{story.description}</Description>
                  <Link to={`/successes/${story.story.post_name}/`}>
                    <Button style="dark">Read More</Button>
                  </Link>
                </Content>
              </SlideContent>
            </Slide>
          ))}
        </Slider>
      </SliderWrapper>
      <DisclaimerText className="mt-8">We use pseudonyms and likenesses of similar children, not portraits.  We avidly protect the identities of the children and families we assist.</DisclaimerText>
    </FeaturedStoriesWrapper>
  );
};

export default FeaturedStories;
