import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { styled } from "twin.macro";
import Button from "../PageComponents/Button";

export const StoriesGrid = styled.ul`
  text-align: center;
  ${({ noPadding }) => (noPadding ? null : "padding: 60px 47px;")}

  @media (min-width: 768px) {
    ${({ noPadding }) => (noPadding ? null : "padding: 90px 63px 30px;")}
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (min-width: 1024px) {
    ${({ noPadding }) => (noPadding ? null : "padding: 120px 80px 0;")}
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const GridTitle = styled.h3`
  font-weight: 600;

  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.83px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 55px;
    letter-spacing: -2.51px;
    margin-bottom: 60px;
    width: 100%;
  }

  @media (min-width: 1024px) {
    font-size: 90px;
    line-height: 91px;
    line-height: -4.11px;
    margin-bottom: 90px;
  }
`;

const Story = styled.li`
  text-align: left;
  margin-bottom: 60px;

  @media (min-width: 768px) {
    width: calc(50% - 20px);
  }
  @media (min-width: 1024px) {
    width: calc(33.3% - 33px);
  }
`;

const StoryImage = styled.div`

  > a {
    position: relative;
    padding-top: 75%;
    width: 100%;
    overflow: hidden;
  }

  img {
    object-fit: cover;
    // object-position: 50%0;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 10px;
  }
`;

const StoryTitle = styled.p`
  font-weight: 600;

  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.56px;
    margin-bottom: 7px;
  }

  @media (min-width: 1024px) {
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.68px;
    margin-bottom: 5px;
  }
`;

const Age = styled.p`
  font-weight: 600;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.11px;
  margin-bottom: 2px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.15px;
    margin-bottom: 5px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 23px;
  }
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.12px;
  margin-bottom: 6px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 26px;
    line-height: -0.16px;
    margin-bottom: 13px;
  }
`;

const ReadMore = styled.div`
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    margin: 30px auto 60px;
  }
`;

export const StoryCards = ({ stories }) => {
  return (
    <>
      {stories.map((story, index) => (
        <Story key={index}>
          <StoryImage bg={story?.acf?.image?.source_url}>
            <Link to={`/successes/${story?.slug}/`} className="block">
              <img src={story?.acf?.image?.source_url} alt={story?.acf?.image?.alt ? story?.acf?.image?.alt : story?.title} />
            </Link>
          </StoryImage>
          <StoryTitle>{story?.title}</StoryTitle>
          <Age>{story?.acf?.age}</Age>
          <Description>{story?.acf?.snippet}</Description>
          <Link to={`/successes/${story?.slug}/`}>
            <Button style={"minimal_arrow_diagonal"}>Read Story</Button>
          </Link>
        </Story>
      ))}
    </>
  )
}

const StoriesGridComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPost(
        filter: { categories: { elemMatch: { name: { eq: "Story" } } } }
      ) {
        nodes {
          id
          acf {
            age
            image {
              source_url
            }
            snippet
          }
          title
          slug
        }
      }
    }
  `);
  // const { allWordpressPost } = data;
  const [displayedStories, setDisplayedStories] = useState(
    data.allWordpressPost.nodes.slice(0, 4) || []
  );

  const loadMoreStories = () => {
    const newSliceEnd = displayedStories.length + 4;
    const newList = data.allWordpressPost.nodes.slice(0, newSliceEnd);

    setDisplayedStories(newList);
  };

  return (
    <StoriesGrid>
      <GridTitle>More Families We've Helped</GridTitle>
      <StoryCards stories={displayedStories} />
      {displayedStories.length < data.allWordpressPost.nodes.length && (
        // display Read More when we have more stories
        <ReadMore>
          <Button style="dark" onClick={loadMoreStories}>
            Read More
          </Button>
        </ReadMore>
      )}
    </StoriesGrid>
  );
};

export default StoriesGridComponent;
